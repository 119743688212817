<template>
    <div>
        <v-dialog
            v-model="showDialogUnclosedDeal"
            transition="dialog-top-transition"
            persistent
            width="500"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    Deal not closed
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Reason</label>
                            <v-combobox
                                v-model="reasonSelected"
                                :rules=[validations.required]
                                :items="listReasonUnclosedDeal"
                                item-text="description"
                                item-value="id"
                                clearable
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Description</label>
                            <v-textarea
                                v-model="description"
                                :rules=[validations.required]
                                rows="4"
                                row-height="23"
                                auto-grow
                                outlined
                            >
                            </v-textarea>

                        </v-col>
                    </v-row>
                    <v-row 
                        v-if="userLoggedGetters.profile.inputClientConsiderationsUnclosedDeals == 1"
                    >
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Client Consideration</label>
                            <v-textarea
                                v-model="descriptionComplement"
                                rows="4"
                                row-height="23"
                                auto-grow
                                outlined
                            >
                            </v-textarea>

                        </v-col>
                    </v-row>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions style="padding: 15px !important;">
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel"
							outlined
							@click="cancel"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2"
							outlined
                            @click="confirm"
						>
							Confirm
						</v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />
	</div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import { required } from "@/utilities/Rules";
    import ActionDialog from "@/components/Layout/ActionDialog";

	export default {

        components: {
            ActionDialog
        },

        mixins: [Helpers],

        props: {
			
            idAux: {
                type: Number,
                default: 0
            },
			
            id: {
                type: Number,
                default: 0
            },
			
            idService: {
                type: Number,
                default: 0
            },

			showDialogUnclosedDeal: {
				default: false
			},
		},
		
        data: () => ({
			
            reasonSelected: null,
            description: "",
            descriptionComplement: "",
            listReasonUnclosedDeal: [],

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },
            
            validations: {
                required: required
            }
		}),

        watch: {
            async "idAux"() {
				this.getRegisters();
            },
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
        },

        methods: {

            cancel() {
                this.$emit('update:showDialogUnclosedDeal', false);
            },

            async getRegisters() {

                if (this.id != 0) {
                    const response = await this.$store.dispatch("serviceModule/GetUnclosedDealById", this.id);
                    
                    if (response.success) {
                        const unclosedDealRequest = response.result;

                        this.reasonSelected = unclosedDealRequest.reasonUnclosedDealSelected;
                        this.description = unclosedDealRequest.description;
                        this.descriptionComplement = unclosedDealRequest.descriptionComplement;
                    }
                }
            },

            async getLists() {
                this.listReasonUnclosedDeal = await this.$store.dispatch("reasonUnclosedDealModule/List");
            },

            async formIsValid() {
                let isValid = true;

                if (this.reasonSelected == null || this.reasonSelected == undefined || this.reasonSelected.id == 0 || 
                    this.description == null || this.description == undefined || this.description.toString().trim() == "") {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    isValid = false;
                }

                return isValid;
            },

            async confirm() {

                let message = 'You will CLOSE this JOB, confirm your decision?'

                if (this.id != 0) {
                    message = 'Confirm saving the changes?'
                }

                if (await this.formIsValid() == true) {
                    this.dialog = {
                        show: true,
                        headerText: 'Confirmation',
                        bodyText: message,
                        methodConfirm: this.saveUnclosedDeal,
                        params: 0
                    };
                }
            },

            async saveUnclosedDeal() {

                let serviceUnclosedDealRequest = null;
                let idReasonUnclosedDeal = 0;

                if (this.reasonSelected != null && this.reasonSelected != undefined) {
                    idReasonUnclosedDeal = this.reasonSelected.id;
                }

                serviceUnclosedDealRequest = {
                    id: this.id,
                    idService: this.idService,
                    idReasonUnclosedDeal,
                    description: this.description,
                    descriptionComplement: this.descriptionComplement,
                }

                const result = await this.$store.dispatch("serviceModule/CreateUpdateUnclosedDeal", serviceUnclosedDealRequest);

                if (result.success === true) {
                    this.showToast("success", "Success!", "Deal closed successfully!");
                    this.$emit('methodConfirmToCall');
                    this.cancel();
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                }
            }
        },

        created() {
            this.getLists();
        }
    };
</script>

<style scoped>

    .v-btn {
        height: 45px !important;
        width: 150px;
    }

</style>